import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { catchError, EMPTY, Observable, of, shareReplay } from 'rxjs';

import { environment } from '../../../environments/environment';
import { getServiceRoot } from '../../../environments/utils';
import { UserEntitlements } from '../models/entitlements';

export const WINDOW_TOKEN = new InjectionToken('Window');

@Injectable({ providedIn: 'root' })
export class EntitlementsService {
    private clientCode: string | undefined;
    private entitlements$: Observable<UserEntitlements> | undefined;

    constructor(
        private readonly http: HttpClient,
        @Inject(WINDOW_TOKEN) readonly window: Window,
    ) {}

    getEntitlements(clientCode: string): Observable<UserEntitlements> {
        if (!this.entitlements$ || this.clientCode !== clientCode) {
            this.entitlements$ = this.fetchEntitlements(clientCode).pipe(shareReplay(1));
            this.clientCode = clientCode;
        }
        return this.entitlements$;
    }

    private fetchEntitlements(clientCode: string): Observable<UserEntitlements> {
        return this.http
            .get<UserEntitlements>(`${environment.api}/${clientCode}/entitlements`)
            .pipe(
                catchError((error): Observable<UserEntitlements> => {
                    if (this.isUserUnauthorized(error)) {
                        this.redirectToLoginPage();
                        return EMPTY;
                    } else {
                        return of(noEntitlements());
                    }
                }),
            );
    }

    private redirectToLoginPage(): void {
        this.window.location.href = `${getServiceRoot(this.window.location)}/wauth/login?continue_url=${encodeURIComponent(this.window.location.href)}`;
    }

    private isUserUnauthorized(error: HttpErrorResponse): boolean {
        return error?.status === 401 || error?.error?.statusCode === 401;
    }
}

export function noEntitlements(): UserEntitlements {
    return {
        user: { username: '' },
        canViewNamedQueries: false,
        canManageNamedQueries: false,
        canAdminNamedQueries: false,
        canManageConversableTypes: false,
        clientsWithAdminNamedQueries: [],
        clientsWithManageNamedQueries: [],
        canManageCustomFields: false,
    };
}
